import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (event.target.closest(".dropdown-container")) return;
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Close the dropdown when the route changes
    setIsOpen(false);
  }, [location]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the user.";
        toast.error(errorMessage);
      });
  }, []);

  return (
    <div className="relative inline-block dropdown-container">
      {/* Dropdown toggle button */}
      <button
        onClick={toggleDropdown}
        className="relative z-10 flex items-center gap-2 p-2 text-gray-600 bg-white border border-gray-200 rounded-md outline-none focus:outline-none min-w-fit"
      >
        <i class="fa-regular fa-circle-user"></i>
        <i class="text-xs fa-solid fa-angle-down"></i>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-0 z-20 w-56 py-2 mt-2 overflow-hidden origin-top-right bg-white rounded-md shadow-xl ">
          <div className="flex items-center p-3 -mt-2 text-sm text-gray-600 transition-colors duration-300 transform">
            {/* <img
              className="flex-shrink-0 object-cover mx-1 rounded-full w-9 h-9"
              src="https://images.unsplash.com/photo-1523779917675-b6ed3a42a561?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8d29tYW4lMjBibHVlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=face&w=500&q=200"
              alt="jane avatar"
            /> */}
            <i class="fa-regular fa-circle-user text-2xl mx-1"></i>
            <div className="mx-1">
              <p className="m-0 text-sm font-semibold text-gray-700 ">
                {user?.name}
              </p>
              {/* <p className="m-0 text-sm text-gray-500 break-all">
                sufiyanirfan1613@gmail.com
              </p> */}
            </div>
          </div>

          <hr className="border-gray-200 " />

          <Link
            to="/order"
            className="block px-4 py-2 text-sm text-gray-600 capitalize transition-colors duration-300 transform  hover:bg-gray-100 "
          >
            Orders
          </Link>

          <Link
            to="/story"
            className="block px-4 py-2 text-sm text-gray-600 capitalize transition-colors duration-300 transform  hover:bg-gray-100 "
          >
            Stories
          </Link>

          <Link
            to="/film"
            className="block px-4 py-2 text-sm text-gray-600 capitalize transition-colors duration-300 transform  hover:bg-gray-100 "
          >
            Films
          </Link>

          <hr className="border-gray-200" />

          <Link
            to="/change-password"
            className="block px-4 py-2 text-sm text-gray-600 capitalize transition-colors duration-300 transform  hover:bg-gray-100 "
          >
            Change Password
          </Link>

          <button
            onClick={handleLogout}
            className="block px-4 py-2 text-sm text-gray-600 capitalize transition-colors duration-300 transform  hover:bg-gray-100 w-full text-start"
          >
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Close the dropdown when the route changes
    setIsOpen(false);
  }, [location]);

  return (
    <nav className="max-w-[1000px] mx-auto top-0 left-0 right-0 z-50 w-full px-4 py-4">
      <ToastContainer />
      <div className="flex justify-between items-center">
        <div className="flex flex-start items-center lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-700 text-base rounded-md focus:outline-none"
          >
            {isOpen ? (
              <i className="fa-solid fa-xmark"></i>
            ) : (
              <i className="fa-solid fa-bars"></i>
            )}
          </button>
        </div>

        <div className="flex justify-center items-center w-full pl-6">
          <Link to="/" className="cursor-pointer">
            <img
              src="https://melo-story.s3.eu-north-1.amazonaws.com/logo.webp"
              alt="Chatbot"
              className="w-auto max-w-64 h-16 sm:h-auto"
              style={{ width: "130px", height: "43px" }}
            />
          </Link>
        </div>

        {isAuthenticated ? (
          <Dropdown />
        ) : (
          <Link to="/login" className="text-gray-500 py-2 text-sm font-medium">
            LOGIN
          </Link>
        )}
      </div>
      <div className="hidden lg:flex justify-center space-x-4 mt-4">
        <Link
          to="/start-your-story"
          className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
        >
          Start your Story
        </Link>
        <Link
          to="/turn-your-story-into-a-film"
          className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
        >
          Turn your Story into a film
        </Link>
        {/* <Link
          to="/continue-your-story"
          className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
        >
          Continue your story (film & photo)
        </Link> */}
        <Link
          to="/featured-stories"
          className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
        >
          Featured Stories
        </Link>
        <Link
          to="/contact-us"
          className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
        >
          Contact Us
        </Link>
      </div>
      <div
        className={`fixed top-16 inset-0 bg-white z-50 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out flex flex-col`}
      >
        <div className="flex flex-col items-start p-4 space-y-4">
          <Link
            to="/start-your-story"
            className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
          >
            Start your Story
          </Link>
          <Link
            to="/turn-your-story-into-a-film"
            className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
          >
            Turn your Story into a film
          </Link>
          {/* <Link
            to="/continue-your-story"
            className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
          >
            Continue your story (film & photo)
          </Link> */}
          <Link
            to="/featured-stories"
            className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
          >
            Featured Stories
          </Link>
          <Link
            to="/contact-us"
            className="text-gray-800 hover:text-gray-600 px-3 py-2 text-sm font-medium"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
