import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ResultScreen.css";

const ResultScreen = ({ finalMessg, setStep }) => {
  const navigate = useNavigate();
  // const [product, setProduct] = useState(null);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/api/category/story`)
  //     .then((response) => {
  //       const result = response.data.data;
  //       setProduct(result[0]);
  //     })
  //     .catch((error) => {
  //       const errorMessage =
  //         error.response?.data?.message ||
  //         error.message ||
  //         "An unexpected error occurred while fetching the story.";
  //       toast.error(errorMessage);
  //     });
  // }, []);

  const handlePurchaseClick = () => {
    // const productDetails = {
    //   name: product?.name,
    //   price: product?.basePrice,
    //   description: product?.description,
    // };
    const productDetails = {
      name: "Story Bundle",
      price: 50,
      description: `<ol class="ml-4">
            <li class="text-sm text-gray-500">Your Story</li>
            <li class="text-sm text-gray-500">Your Values</li>
            <li class="text-sm text-gray-500">Your content strategy</li>
            <li class="text-sm text-gray-500">
              Your digital media schedule
            </li>
          </ol>`,
    };
    localStorage.removeItem("checkoutProduct"); // Clear only checkoutProduct
    localStorage.setItem("checkoutProduct", JSON.stringify(productDetails));
    navigate("/checkout");
  };

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />

      <div
        dangerouslySetInnerHTML={{ __html: finalMessg }}
        className="text-content pb-16 blurred-content"
      />

      <div className="max-w-4xl mx-auto fixed pt-4 pb-8 bottom-0 left-0 right-0 w-full bg-white border-t border-gray-200">
        <div className="flex items-center justify-center gap-4">
          <button
            onClick={handlePurchaseClick}
            className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            Purchase for $50
          </button>
          <button
            onClick={() => {
              setStep(1);
              localStorage.setItem("meloChatStep", 1);
              localStorage.removeItem("meloChatMessages");
              localStorage.removeItem("meloChatFinalMessg");
            }}
            className="rounded-md transition-all bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Start again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultScreen;
