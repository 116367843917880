import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useCache } from "../../CacheContext";

const SingleStory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { getCacheData, setCacheData } = useCache();
  const [story, setStory] = useState(() => getCacheData(`story_${id}`) || null);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      try {
        console.log("api call made");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/story/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setStory(response.data.data);
        setCacheData(`story_${id}`, response.data.data);
        setPageLoading(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the story.";
        toast.error(errorMessage);
        setPageLoading(false);
      }
    };

    if (!story) {
      fetchData();
    } else {
      setPageLoading(false);
    }
  }, [id, story, setCacheData]);

  if (pageLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!story) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No story found</p>
      </div>
    );
  }

  const turnStory = (id) => {
    navigate(`/turn-your-story-into-a-film/${id}`);
  };

  const copyToClipboard = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Function to traverse and convert HTML to formatted text
    const traverseAndFormat = (node, prefix = "") => {
      let result = "";

      const traverse = (node, listPrefix = "") => {
        if (node.nodeType === Node.TEXT_NODE) {
          result += node.nodeValue;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "OL") {
            let count = 1;
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += `${count}. `;
                traverse(child, `${count}. `);
                result += "\n"; // Add newline after each list item
                count++;
              } else {
                traverse(child);
              }
            });
          } else if (node.tagName === "UL") {
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += "• ";
                traverse(child, "• ");
                result += "\n"; // Add newline after each list item
              } else {
                traverse(child);
              }
            });
          } else {
            node.childNodes.forEach(traverse);
          }

          if (node.tagName === "P") {
            result += "\n";
          }
        }
      };

      traverse(node);

      // Remove multiple line breaks
      result = result.replace(/\n\s*\n/g, "\n\n");

      return result;
    };

    const textToCopy = traverseAndFormat(tempDiv).trim();

    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    });
  };

  const generateAndDownloadPDF = async () => {
    try {
      setLoading(true);
      const htmlContent =
        `<h4 style="text-align:center; font-size:22px;">${story.name}</h4>` +
        story.description;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/generate-pdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent }),
        }
      );

      if (!response.ok) {
        throw new Error("Error saving pdf file");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "story.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (e) {
      toast.error("Error: " + e.message);
      setLoading(false);
    }
  };

  const htmlToPlainText = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Function to traverse and convert HTML to formatted text
    const traverseAndFormat = (node, prefix = "") => {
      let result = "";

      const traverse = (node, listPrefix = "") => {
        if (node.nodeType === Node.TEXT_NODE) {
          result += node.nodeValue;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "OL") {
            let count = 1;
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += `${count}. `;
                traverse(child, `${count}. `);
                result += "\n"; // Add newline after each list item
                count++;
              } else {
                traverse(child);
              }
            });
          } else if (node.tagName === "UL") {
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += "• ";
                traverse(child, "• ");
                result += "\n"; // Add newline after each list item
              } else {
                traverse(child);
              }
            });
          } else {
            node.childNodes.forEach(traverse);
          }

          if (node.tagName === "P") {
            result += "\n";
          }
        }
      };

      traverse(node);

      // Remove multiple line breaks
      result = result.replace(/\n\s*\n/g, "\n\n");

      return result;
    };

    const textToCopy = traverseAndFormat(tempDiv).trim();
    return textToCopy;
  };

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <div>
        <h3 className="text-center font-semibold leading-6 text-gray-900">
          {story.name}
        </h3>
        <div
          dangerouslySetInnerHTML={{ __html: story.description }}
          className="text-content pb-16"
        />
      </div>

      <div className="pb-24 flex flex-col items-center space-y-2">
        <h3 className="text-center font-semibold leading-6 text-gray-900">
          Turn your Story into a film
        </h3>

        <video
          controls
          autoPlay
          muted
          playsInline
          preload="auto"
          className="max-h-[600px] mb-4"
        >
          <source
            src="https://melo-story.s3.eu-north-1.amazonaws.com/Dodie+Jay+Hair/Dodie+-+Draft+4+-+Reel+Format+(1)+(1).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <img
          src="https://melo-story.s3.eu-north-1.amazonaws.com/film2.png"
          className="max-h-[420px]"
        ></img>
        <img
          src="https://melo-story.s3.eu-north-1.amazonaws.com/film3.png"
          className="max-h-[320px]"
        ></img>
        <br></br>
        <br></br>
        {story.isFilm ? (
          <button
            className="rounded-md transition-all w-full bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm"
            disabled
          >
            Already a film
          </button>
        ) : (
          <button
            onClick={() => {
              turnStory(story._id);
            }}
            className="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            Buy your film
          </button>
        )}
      </div>

      <div className="max-w-4xl mx-auto fixed pt-4 pb-8 bottom-0 left-0 right-0 w-full bg-white border-t border-gray-200">
        <div className="flex items-center justify-center gap-4">
          <i
            className="relative cursor-pointer rounded-md transition-all bg-white px-4 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 fas fa-copy"
            onClick={() =>
              copyToClipboard(story.name + "\n\n" + story.description)
            }
            title="Copy to Clipboard!"
          >
            {showTooltip && (
              <div className="absolute -top-8 right-0 text-xs rounded bg-black text-white py-1 px-2">
                Copied!
              </div>
            )}
          </i>
          <button
            onClick={() => generateAndDownloadPDF()}
            className="rounded-md transition-all bg-white px-4 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            title="Download PDF"
            disabled={loading}
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              <i className="fas fa-file-pdf"></i>
            )}
          </button>
          <button
            className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
            onClick={() =>
              shareOnMobile({
                title: htmlToPlainText(story.name + "\n\n" + story.description),
              })
            }
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleStory;
