import { Link } from "react-router-dom";
import "./Home.css";

export const Home = () => (
  <div className="flex flex-col items-center min-h-screen startScreen">
    <div className="flex-1 flex items-center justify-center w-full">
      <div className="flex items-center justify-center flex-col pt-32 sm:pt-64">
        <Link
          to="/start-your-story"
          className="bg-black text-white rounded-md py-2 px-4"
        >
          START YOUR STORY
        </Link>
      </div>
    </div>
  </div>
);
